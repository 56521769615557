import { BuildingPart } from "@connectedliving/common/lib/firestore/BuildingPart";
import { ChannelNotificationsState } from "@connectedliving/common/lib/firestore/ChannelNotificationsState";
import { supportedCountries } from "@connectedliving/common/lib/firestore/supportedCountries";
import { LanguageCode } from "@connectedliving/common/lib/firestore/supportedLanguages";
import { teamUnsecuredUntilMemberCount } from "@connectedliving/common/lib/firestore/Team";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import castAs from "@connectedliving/common/lib/utilities/lang/castAs";
import { IonIcon, IonText } from "@ionic/react";
import firebase from "firebase/compat/app";
import { flameSharp, flashSharp, languageOutline } from "ionicons/icons";
import {
  defaultFormatters,
  LocaleFormatters,
  LocaleTranslations,
} from "src/state/i18n/i18nConfig";
import { GeocodeAddressComponentType } from "src/utilities/googlePlaces/getGeocodeAddressComponentDict";
import { makeLocale } from "src/utilities/i18n/makeLocale";
import enUs from "./en-us";

export default makeLocale<LocaleTranslations, LocaleFormatters, "de">({
  tag: "de",
  formatters: defaultFormatters,
  translations(t, formatters): LocaleTranslations {
    const common: LocaleTranslations["common"] = {
      add: t`Hinzufügen`,
      search: t`Nachbar*in suchen`,
      inviteNeighbor: t`Lade deine Nachbar*innen ein`,
      create: t`Erstellen`,
      update: t`Speichern`,
      done: t`Fertig`,
      back: t`Zurück`,
      errorMessage: t`Ein Fehler ist aufgetreten, bitte versuche es nochmal.`,
      name: t`Name`,
      httpsErrorMessage: (error: { message: string; code: string }) =>
        t`${error.message} (${error.code})`,
      copiedToClipboard: t`In die Zwischenablage kopiert`,
      next: t`Weiter`,
      ok: t`Ok`,
      skip: t`Überspringen`,
      cancel: t`Abbrechen`,
      save: t`Speichern`,
      members: t`Mitglieder`,
      genericError: t`Ein Fehler ist aufgetreten:`,
      connectedLivingMessenger: t`Connected Living Messenger`,
      join: t`Beitreten`,
      confirm: t`Bestätigen`,
      you: t`Du`,
      contactSupport: t`Schreib uns`,
      retry: t`Wiederholen`,
      buildingParts: castAs<Record<BuildingPart, string>>({
        "front-house": t`Vorderhaus`,
        "back-house": t`Hinterhaus`,
        "rear-house": t`Quergebäude`,
        "garden-house2": t`Gartenhaus`,
        "garden-house": t`Remise`,
        "house-wing": t`Seitenflügel`,
        "house-wing-left": t`Seitenflügel Links`,
        "house-wing-right": t`Seitenflügel Rechts`,
        "side-house": t`Seitenhaus`,
        "side-house-left": t`Seitenhaus Links`,
        "side-house-right": t`Seitenhaus Rechts`,
        "first-hof": t`1. Hof`,
        "second-hof": t`2. Hof`,
        "third-hof": t`3. Hof`,
        "fourth-hof": t`4. Hof`,
        "fifth-hof": t`5. Hof`,
        "sixth-hof": t`6. Hof`,
      }),
      supportedCountries,
      floorName: (i: number) => {
        if (i === -1) {
          return `Untergeschoss`;
        }
        if (i === 0) {
          return `Erdgeschoss`;
        }
        return `${i}. OG`;
      },
      language: castAs<Record<LanguageCode, string>>({
        de: t`Deutsch`,
        en: t`Englisch`,
      }),
      locales: enUs.translations.common.locales,
      validations: {
        isRequired: t`ist erforderlich`,
        validEmailAddress: t`Muss eine E-mail Adresse sein`,
        characterMinimum: (min: number) =>
          t`muss mindestens ${min} Zeichen lang sein`,
        characterMaximum: (max: number) =>
          t`darf nicht länger als ${max} Zeichen sein`,
        numberMinimum: (min: number) => t`muss größer als ${min} sein`,
        numberMaximum: (max: number) => t`muss kleiner als ${max} sein`,
        mustBeWholeNumber: t`muss eine ganze Zahl sein`,
      },
      startNewCommunityInfoModal: {
        title: t`Eine neue Gemeinschaft gründen`,
        body: (onClickFn: () => void) => (
          <>
            <p>
              Eine <strong>Gemeinschaft</strong> ist ein privater Online-Raum
              für dich und deine Nachbar*innen, dem man nur{" "}
              <strong>auf Einladung</strong> beitreten kann.
            </p>

            <p>
              <em>
                Hinweis: Für neue Gemeinschaften (mit weniger als{" "}
                {teamUnsecuredUntilMemberCount} Mitgliedern) ist der
                Einladungscode öffentlich, damit es einfach ist, alle an Bord zu
                holen.
              </em>
            </p>

            <p>
              Du kannst in deiner Gemeinschaft <strong>Channels</strong> für
              Themen erstellen, die für dich relevant sind. Channels sind
              Gruppenchats, die jede*r in der Gemeinschaft finden kann und denen
              jede*r beitreten kann.
            </p>

            <p>
              Und wenn du mit jemandem unter vier Augen sprechen möchtest,
              kannst du eine <strong>Direktnachricht</strong> senden.
            </p>

            <p>
              <strong>Du kannst eine neue Gemeinschaft gründen, wenn:</strong>
            </p>
            <ul>
              <li>Du bist die erste Person hier</li>
              <li>
                Du möchtest andere einladen, sich dir anzuschließen (wir
                schicken dir gedruckte Einladungen, um dies zu erleichtern)
              </li>
            </ul>
            <p>
              Wenn du dir unsicher bist, wende dich an{" "}
              <IonText color="primary" onClick={() => onClickFn()}>
                <u>unseren Support</u>
              </IonText>{" "}
              und wir helfen dir gerne weiter.
            </p>
          </>
        ),
      },
      addressComponentNames: castAs<
        Record<GeocodeAddressComponentType, string>
      >({
        country: t`Land`,
        administrative_area_level_1: t`Bundesland`,
        postal_code: t`Postleitzahl`,
        locality: t`Stadt`,
        street_number: "Hausnummer",
        route: t`Straße`,
      }),
      tipBanners: {
        bestResults: { text: t`Funktioniert am Besten!`, icon: flameSharp },
        quickest: { text: t`Am schnellsten!`, icon: flashSharp },
      },
      cantReachNetwork: t`Es scheint du hast gerade keine Internetverbindung`,
      unknownUser: t`(Unbekannter Nutzer)`,
    };
    return {
      EditTeamPage: {
        title: t`Gemeinschaft bearbeiten`,
        numberOfApartments: t`Anzahl der Wohnungen`,
        teamUpdated: t`Deine Gemeinschaft wurde aktualisiert`,
        buildingsWhiteListLabel: t`Gebäude in deiner Gemeinschaft`,
        countBuildingPartsSelected: (num: number) =>
          t`${num} Gebäude ausgewählt`,
      },
      AddTeamLocationPage: {
        form: {
          streetName: t`Straße`,
          streetNumber: t`Hausnummer`,
          city: t`Stadt`,
          state: t`Bundesland`,
          country: t`Land`,
          postcode: t`Postleitzahl`,
        },
        title: t`Füge eine Adresse hinzu`,
        teamLocationUpdated: ({
          streetName,
          streetNumber,
        }: {
          streetName: string;
          streetNumber: string;
        }) => t`${streetName} ${streetNumber} wurde hinzugefügt`,
      },
      EditTeamLocationPage: {
        form: {
          streetName: t`Straße`,
          streetNumber: t`Hausnummer`,
          city: t`Stadt`,
          state: t`Bundesland`,
          country: t`Land`,
          postcode: t`Postleitzahl`,
        },
        title: t`Adresse bearbeiten`,
        teamLocationUpdated: t`Deine Adresse wurde aktualisiert`,
      },
      TeamAdminPage: {
        apartments: t`Wohnungen`,
        members: t`Mitglieder`,
        communityAdmin: t`Administrator der Gemeinschaft`,
        locationLabel: (
          teamLocations: firebase.firestore.QueryDocumentSnapshot<TeamLocation>[],
        ) => (teamLocations.length === 1 ? t`Adresse` : t`Adressen`),
        buildingsLabel: () => t`Gebäude`,
        location: enUs.translations.TeamAdminPage.location,
        editTeamButton: t`Bearbeite deine Gemeinschaft`,
        unknownAdmin: t`Kein aktueller Adminstrator`,
        yourCommunity: t`Deine Gemeinschaft`,
        addLocationButton: t`Adresse hinzufügen`,
        addLocationPlaceholder: t`Füge eine Adresse hinzu`,
        contactAdmin: (
          userProfileData: UserProfile | undefined,
          onClickFn: () => void,
        ) =>
          userProfileData ? (
            <>
              Um Änderungen an deiner Gemeinschaft vorzunehmen, sende eine
              Nachricht an {formatFullName(userProfileData)} oder{" "}
              <IonText color="primary" onClick={() => onClickFn()}>
                schreib uns!
              </IonText>
            </>
          ) : (
            <>
              Um Änderungen an deiner Gemeinschaft vorzunehmen, bitte{" "}
              <IonText color="primary" onClick={() => onClickFn()}>
                schreib uns!
              </IonText>
            </>
          ),
        contactUs: (onClickFn: () => void) => (
          <>
            Nur du kannst Änderungen an deiner Gemeinschaft vornehmen. Hast du
            Fragen?{" "}
            <IonText color="primary" onClick={() => onClickFn()}>
              Schreib uns!
            </IonText>
          </>
        ),
        teamLocationUpdated: ({
          streetName,
          streetNumber,
        }: {
          streetName: string;
          streetNumber: string;
        }) => t`${streetName} ${streetNumber} wurde hinzugefügt`,
      },
      SearchableUserProfileList: {
        noResults: t`Leider gibt es hier niemanden mit diesem Namen`,
      },
      UserProfilesListPage: { people: t`Nachbar*innen` },
      ViewUserProfilePage: {
        edit: t`Bearbeiten`,
        labels: {
          name: t`Name`,
          address: t`Adresse`,
          floor: t`Etage`,
          building: t`Gebäude`,
          extraLocationData: t`Zusätzliche Informationen`,
          preferredLanguage: t`Bevorzugte Sprache`,
        },
        messageVisibilityNotification: () => (
          <>
            Nachrichten zwischen dir und dem Connected Living Support sind für
            das Connected Living Team sichtbar.
            <br />
            <br /> Schick uns eine Nachricht und ein Mensch wird dir antworten
            (we also speak English ❤️)
          </>
        ),
        noLanguageSelected: t`Keine Sprache ausgewählt`,
        sendDirectMessage: t`Direktnachricht schreiben`,
        title: t`Profil`,
      },
      AuthPage: {
        title: t`Mit deinen Nachbar*innen verbinden`,
        subtitle: t`Gib deine E-Mail-Adresse ein, um dich anzumelden oder ein Konto zu erstellen`,
      },
      common,
      ChannelsListPage: {
        channels: t`Channels`,
        directMessages: t`Direktnachrichten`,
        viewMoreChannels: t`Siehe weitere Channels`,
        newDirectMessage: t`Neue Direktnachricht`,
        confirm: t`Team wechseln`,
        cancel: t`Schließen`,
        growCommunityCard: {
          superTitle: t`Hilf deiner Gemeinschaft wachsen`,
          title: (numberOfNeighbors: number) =>
            numberOfNeighbors === 1 ? (
              <strong>Los geht&apos;s</strong>
            ) : (
              <>
                {" "}
                <strong>{numberOfNeighbors} Nachbarn</strong>
              </>
            ),
          body: (numberOfNeighbors: number, numberOfApartments: number) =>
            numberOfNeighbors === 1 ? (
              <>
                Du bist die erste Person hier! Klicke hier und lade deine
                Nachbarn ein
              </>
            ) : (
              <>
                haben sich von insgesamt {numberOfApartments} Wohnungen
                angeschlossen! Klicke hier, um den Rest einzuladen
              </>
            ),
        },
      },
      EditMyUserProfilePage: {
        profileSaved: t`Dein Profil wurde gespeichert`,
        atSignInvalidNameInput: t`Namen können kein "@" enthalten`,
        title: t`Bearbeite dein Profil`,
        changeImage: t`Ändere dein Profilbild`,
        addImage: t`Bild hinzufügen`,
        placeHolders: {
          firstName: t`Wie lautet dein Vorname?`,
          lastName: t`Und dein Nachname?`,
          address: t`Adresse`,
          floor: t`Etage`,
          building: t`Gebäude`,
          apartmentNumber: t`Wohungsnummer, c/o, etc`,
        },
        headers: {
          name: t`Name`,
          location: t`Adresse`,
        },
      },
      TeamCreatePage: {
        buttonName: t`Gemeinschaft erstellen`,
        additionalInformation: t`Du kannst später noch Änderungen machen und weitere Adressen hinzufügen`,
        contactSupport: (supportEmailElement: JSX.Element) => (
          <>Fragen? Schreib uns einfach an {supportEmailElement}</>
        ),
        helperTexts: {
          state: t`Nur Adressen in Deutschland werden unterstützt`,
          numberOfApartments: t`Dies hilft uns, dich beim Aufbau deiner Gemeinschaft besser zu unterstützen`,
        },
        cardLabels: {
          address: t`Adresse`,
          numberOfApartments: t`Anzahl der Wohnungen`,
        },
        errorMessage: t`Oh nein! Etwas ist schiefgegangen, probiere es bitte nochmal`,
        isRequired: t`ist erforderlich`,
        subtitle: t`Du bist zum ersten Mal hier? Gründe eine neue Gemeinschaft und lade dann deine Nachbar*innen ein.`,
        title: t`Starte eine neue Gemeinschaft`,
        step: (i: number) => t`${i}. Schritt`,
        skip: t`überspringen`,
        slides: {
          registerYourAddress: {
            title: t`Melde deine Adresse an`,
            body: t`Erstelle eine neue Connected Living Gemeinschaft für deine Adresse und deine Nachbar*innen können ganz einfach dazukommen.`,
            imgAlt: t`Standort-Pin eingebettet an einer Straßenecke`,
          },
          receiveInvitations: {
            title: t`Gedruckte Einladungen bestellen`,
            body: t`Wir können dir gern gedruckte Einladungen für deine Nachbarn schicken - das kostet nur ein Paar Euro. Du kannst dir diese auch als PDF herunterladen und selbst ausdrucken - natürlich kostenfrei!`,
            imgAlt: t`Eine Einladung auf einem Manila-Umschlag`,
          },
          inviteYourNeighbors: {
            title: t`Lade deine Nachbar*innen ein`,
            body: t`Wirf die Einladungen einfach in die Briefkästen und siehe zu, wie deine Gemeinschaft wächst!`,
            imgAlt: t`Ein Briefkasten & eine Einladung`,
          },
        },
        searchAddressModal: {
          placeholder: t`Wie ist deine Adresse?`,
          submitText: t`Adresse auswählen`,
        },
      },
      TeamCreatedSuccessPage: {
        appleStoreImgAlt: t`Apple App Store`,
        playStoreImgAlt: t`Google Play Store`,
        welcome: t`Willkommen zu`,
        communityIsReady: t`Deine neue Gemeinschaft ist bereit! Lade die mobile App herunter, um fortzufahren und deine Nachbarn einzuladen.`,
        illustrationAltText: t`Hausgemeinschaft mit Wohnungen und Leuten, die Dinge teilen, mit einem Partyhorn, das Konfetti und Luftschlangen ausbläst, oben`,
      },
      NewTeamChannelPage: {
        tooLong: t`Der Channelname ist etwas zu lang.`,
        alreadyExists: t`Es gibt bereits einen Channel mit diesem Namen`,
        title: t`Neuer Channel`,
        name: t`Name`,
        purpose: t`Wozu dient dieser Channel?`,
        createChannel: t`Erstelle Channel`,
      },
      UserIsBlockedBanner: {
        notification: t`Du hast diesen Benutzer blockiert. Du wirst
        keine Direktnachrichten von dieser Person erhalten und sie wird nicht benachrichtigt
        dass du sie blockiert hast.`,
      },
      ChannelsMessagesHeader: {
        autoTranslate: (
          userProfile: UserProfile,
          languageName: { (language: LanguageCode): string },
        ) =>
          t`Automatische Übersetzung nach ${
            userProfile.language && languageName(userProfile.language)
          } aktiviert`,
        autoTranslateDisabled: t`Automatische Übersetzung deaktiviert`,
      },
      CLMessageInputSmall: {
        couldNotUpload: t`Bild konnte nicht hochgeladen werden`,
        inputPlaceholder: t`Tippe deine Nachricht`,
        attachFiles: t`Dateien anhängen`,
        maxFilesWarning: t`Du hast die maximale Anzahl von Dateien erreicht`,
        closeEmojiPicker: t`Emoji-Auswahl schließen`,
        openEmojiPicker: t`Emoji-Auswahl öffnen`,
      },
      MessageRenderer: {
        cannotUndo: t`Dies kann nicht rückgängig gemacht werden`,
        copyText: t`Kopieren`,
        deleteMessage: t`Nachricht löschen`,
        editMessage: t`Nachricht bearbeiten`,
        reportMessage: t`Nachricht melden`,
        reportToast: {
          header: t`Nachricht gemeldet`,
          message: t`Ein Mitglied unseres Support-Teams könnte dich für weitere Informationen kontaktieren`,
        },
      },
      ChannelPreview: {
        messageDeleted: t`Nachricht gelöscht`,
      },
      DiscoveryChannelsPage: {
        title: t`Weitere Channels`,
        memberOfAll: t`Du bist bereits Mitglied in allen Channels`,
      },
      TeamChannelDetailPage: {
        title: t`Channelübersicht`,
        leaveChannel: t`Channel verlassen`,
        joinChannel: t`Channel beitreten`,
        muteChannel: t`Stumm`,
        unmuteChannel: t`Stumm`,
        yes: t`Ja`,
        no: t`Nein`,
      },
      DirectMessageChannelDetailPage: {
        title: t`Direktnachrichtenübersicht`,
        contactDetails: t`Kontakt Details`,
      },
      ReportUserButton: {
        buttonText: t`Nutzer melden`,
        alert: {
          header: (targetUserName: string) =>
            t`Bist du sicher, dass du ${targetUserName} melden möchtest?`,
          message: t`Ein Mitglied unseres Support-Teams könnte dich für weitere Informationen kontaktieren`,
          inputPlaceholder: t`Beschreibe das Problem`,
        },
        reportUserToast: {
          success: (targetUserName: string) => t`${targetUserName} gemeldet`,
          error: t`Ein Fehler ist aufgetreten. Bitte versuche es erneut.`,
        },
      },
      YouWillBeNotifiedWhen: {
        header: t`Wer wird über neue Nachrichten benachrichtigt?`,
        memberList: t`Nur Mitglieder dieses Channels`,
        channelNotificationsState: castAs<
          Record<ChannelNotificationsState, string>
        >({
          normal: t`Du wirst benachrichtigt`,
          muted: t`Du wirst nicht benachrichtigt, du hast diesen Channel stummgeschaltet`,
          userBlocked: t`Du wirst nicht benachrichtigt, du hast diesen Benutzer blockiert`,
          notAMember: t`Du wirst nicht benachrichtigt, da du kein Mitglied in diesem Channel bist`,
        }),
      },
      WhoCanSeeThis: {
        header: t`Wer kann das sehen?`,
        whoCanSee: {
          anyone: t`Jeder in deinem Gebäude kann das sehen`,
          userSupport: t`Du und das Team von Connected Living`,
          private: t`Nur du und die andere Person`,
        },
        whoCanJoin: {
          anyone: t`Jeder in deinem Gebäude kann beitreten`,
          noOne: t`Niemand anderes kann beitreten`,
        },
      },
      ClosedBetaPage: {
        title: t`Tritt deiner Hausgemeinschaft bei`,
        subtitle: t`Benutze deinen Einladungscode, um deinen Nachbar*innen auf Connected Living beizutreten`,
        inviteCard: {
          title: `Wo finde ich meinen Einladungscode?`,
          body: (onClickFn: () => void) => (
            <>
              {" "}
              Du kannst einen Einladungscode nur von deinen Nachbarn erhalten.
              Schau in deinem Briefkasten nach einer Einladung, oder frage einen
              Nachbarn, der in der App angemeldet ist, oder kontaktiere{" "}
              <IonText color="secondary" onClick={() => onClickFn()}>
                <u>den Support</u>
              </IonText>
              .
            </>
          ),
        },
        illustrationAltText: t`eine Einladung an die Nachbar*innen mit einem rosa QR-Code unten rechts`,
      },
      UserSettingsPage: {
        title: t`Einstellungen`,
        editProfile: t`Profil bearbeiten`,
        yourCommunity: t`Deine Gemeinschaft`,
        shareApp: t`Teile die App`,
        privacyPolicy: t`Datenschutzerklärung`,
        language: t`Sprache`,
        termsOfUse: t`AGB`,
        appVersion: t`App Version`,
        logOut: t`Ausloggen`,
        copiedToClipboard: t`In die Zwischenablage kopiert`,
        deleteAccount: t`Account löschen`,
        leaveCommunity: t`Gemeinschaft verlassen`,
        contactUs: {
          button: t`Kontaktiere uns`,
          alertHeader: t`Hallo! 👋`,
          alertSubheader: t`Hierdurch wird ein Chat mit einem echten Menschen gestartet`,
          alertMessage: t`Unser kleines Team freut sich, von dir zu hören 🙂 Wir werden so schnell wie möglich antworten`,
          alertCancel: t`Abbrechen`,
          alertConfirm: t`Chat öffnen`,
          supportUserInterface: t`Support Interface`,
        },
        myCommunities: t`Meine Gemeinschaften`,
        push: t`Mitteilungen`,
      },
      ShareAppModal: {
        title: t`Teile Connected Living`,
        body: t`Hier kannst du die App mit jemandem zu teilen, der eine neue Gemeinschaft gründen will.`,
        displayQrCode: t`App QR-Code anzeigen`,
        nativeShareModal: {
          shareText: t`Ich nutze Connected Living, um mit meinen Nachbar*innen zu chatten. Das solltest du auch mal ausprobieren! Klick hier, um loszulegen`,
          shareDialogTitle: t`Lade einen Freund zu Connected Living ein`,
        },
      },
      InviteNeighborModal: {
        title: t`Lade deine Nachbar*innen ein`,
        body: t`Neue Mitglieder können einer Gemeinschaft nur mit einem Einladungscode von einem bestehenden Mitglied beitreten.`,
        howToInvite: t`Wie du Nachbarn einlädst`,
        displayInviteCode: t`Einladungscode anzeigen`,
        displayQrCode: t`QR-Code anzeigen`,
        shareInviteLink: t`Einladungslink teilen`,
        nativeShareModal: {
          shareDialogTitle: t`Lade deine Nachbar*innen zu Connected Living ein`,
          shareText: (teamName: string) =>
            `Werde Mitglied unserer Gemeinschaft ${teamName} auf Connected Living`,
          shareCodeText: (teamName: string, inviteCode: string) =>
            t`Benutze diesen Einladungscode, um unserer Gemeinschaft ${teamName} beizutreten: ${inviteCode}`,
        },
        requestStarterKit: {
          buttonLabel: t`Gedruckte Einladungen bestellen`,
        },
        printAtHome: {
          buttonLabel: t`Einladungen zum selbst ausdrucken`,
        },
      },
      HowToInviteNeighborsModal: {
        title: t`Wie du deine Gemeinschaft vergrößern kannst`,
        body: () => (
          <p>
            <strong>Was ist ein Einladungscode?</strong>
            <br />
            Dein Einladungscode ist wie das Passwort für deine Community
          </p>
        ),
        superTitle: (index: number) => <>Option {index}</>,
        options: {
          printAtHome: {
            title: t`Einladungen selbst ausdrucken`,
            body: t`Hast du einen Drucker? Lade eine PDF-Datei herunter, die du sofort ausdrucken und mit deinen Nachbar*innen teilen kannst.`,
            tipBanner: common.tipBanners.quickest,
          },
          shareInviteLink: {
            title: t`Teile den Einladungslink`,
            body: t`Der Einladungslink macht es Leuten, die die App bisher nicht verwenden, einfach, sie herunterzuladen und deiner Community beizutreten. Perfekt, um ihn in einem bereits bestehenden Gruppenchat zu teilen.`,
            tipBanner: null,
          },
          requestStarterKit: {
            title: t`Gedruckte Einladungen bestellen`,
            body: t`Kein Drucker? Kein Problem! Bestelle bei uns ein Starterkit mit gedruckten Einladungen und Postern. Es kostet nur ein paar Euro, um Versand und Material zu decken.`,
            tipBanner: common.tipBanners.bestResults,
          },
          shareInviteCode: {
            title: t`Teile den Einladungscode`,
            body: t`Kopiere den Einladungscode und schicke ihn an einen Nachbar*innen oder Mitbewohner*innen. Sobald sie die App heruntergeladen haben, können sie damit deiner Community beitreten.`,
            tipBanner: null,
          },
          shareQrCode: {
            title: t`Teile den QR-Code`,
            body: t`Der QR-Code öffnet den obigen Einladungslink. Lade ihn als Bild herunter, um ihn in gedruckte Materialien einzufügen, z.B. um ein Poster oder Flyer zu drucken.`,
            tipBanner: null,
          },
        },
      },
      ShowInviteCodeModal: {
        title: t`Dein Einladungscode lautet`,
        body: t`Dein Einladungscode ist wie ein Passwort, um deiner Gemeinschaft beizutreten, teile ihn nur mit Nachbar*innen, die du kennst.`,
        shareCode: t`Einladungscode teilen`,
      },
      ShowQrCodeModal: {
        teamInvite: {
          title: t`QR-Code Einladung`,
          body: t`
          Dieser QR-Code öffnet eine Seite mit Anweisungen, wie du deiner Gemeinschaft beitreten kannst.`,
        },
        appShare: {
          title: t`App teilen QR-Code`,
          body: t`
        Dieser QR-Code öffnet eine Seite mit einem Link zum Herunterladen der App.`,
        },
      },
      OrderStarterKit: {
        title: t`Starterkit Bestellen`,
        description: t`Ein Paket mit gedruckten Einladungen, die du in die Briefkästen deiner Nachbarn werfen kannst, sowie Poster, die du an den Eingängen aufhängen kannst.`,
        includesLabel: t`Enthält:`,
        priceLabel: t`Preis:`,
        invitations: (count: number) => t`${count} Einladungen`,
        postersWithTape: (count: number) => t`${count} Poster mit Tape`,
        price: (cost: number) =>
          `${formatters.formatNumber(cost, "euroCurrency")} Druckkosten`,
        shippingCost: (cost: number) =>
          t`${formatters.formatNumber(cost, "euroCurrency")} Versand`,
        buttonText: t`Zahlen mit PayPal`,
        sizeLabel: t`Größe:`,
        sizeOptions: {
          small: "S",
          medium: "M",
          large: "L",
        },
      },
      ValuePropositionPage: {
        startingSlide: {
          title: t`Wann ist es hilfreich, seine Nachbar*innen zu kennen?`,
          paragraph: t`Alltägliche Probleme sind ein Kinderspiel, wenn du deine Nachbar*innen fragen kannst!`,
          alt: t`Eine Person gibt einer anderen eine Box mit Essen`,
        },
        helpingHand: {
          title: t`Hilfe bekommen`,
          paragraph: t`Etwas Schweres hochtragen? Kannst ein Glas nicht öffnen? Brauchst einen Haussitter? Deine Nachbar*innen helfen dir bestimmt gerne.`,
          alt: t`Zwei Menschen die eine Couch tragen`,
        },
        avoidUnnecessaryPurchases: {
          title: t`Werkzeuge ausleihen`,
          paragraph: t`Du brauchst eine Leiter? Frag deine Nachbar*innen, die haben bestimmt schon eine.`,
          alt: t`Eine Person die eine Leiter trägt`,
        },
        relevantAdvice: {
          title: t`Wichtige Ratschläge holen`,
          paragraph: t`Steigt die Miete? Wartungsprobleme? Brauchst du eine Empfehlung? Frag deine Nachbar*innen.`,
          alt: t`Zwei Menschen sitzen auf einer Couch`,
        },
        findingYourPackage: {
          title: t`Deine Pakete schneller finden`,
          paragraph: t`Du musst nicht mehr an jeder Tür klingeln, um die Person zu finden, die dein Paket angenommen hat, sondern kannst einfach eine Nachricht an deine Nachbar*innen schicken und fragen!`,
          alt: t`Eine Person gibt einer anderen etwas zu Essen.`,
        },
        button: t`Loslegen`,
      },
      UserOnboardingInitialSlide: {
        welcome: t`Willkommen zu`,
        mainText: t`Erstelle ein Profil, um deiner Gemeinschaft beizutreten..`,
        illustrationAltText: t`Hausgemeinschaft mit Wohnungen und Menschen, die Dinge teilen`,
        getStartedButton: t`Loslegen`,
      },
      UserOnboardingDataPrivacySlide: {
        heading: t`Deine Daten sind hier sicher`,
        explanation: () => (
          <>
            <p>
              Dies ist ein sicherer Raum für eure Gemeinschaft. Eure
              Privatsphäre und eure Daten sind geschützt.
            </p>

            <ul>
              <li>
                Nur deine Nachbar*innen können dein Profil sehen und dich
                kontaktieren
              </li>
              <li>Deine Daten werden niemals mit anderen geteilt</li>
              <li>
                Deine Daten werden nicht für Werbung oder Tracking verwendet
              </li>
            </ul>
          </>
        ),

        illustrationAltText: t`Herz mit Datenschutzabbildung`,
      },
      UserOnboardingTranslationLanguageSlide: {
        images: {
          altOne: t`Chat UI mit gemischtsprachigem Text`,
          altTwo: t`Chat UI mit allen Texten auf Englisch übersetzt`,
        },
        headline: t`Nachrichtenübersetzung`,
        explanation: t`Aktiviere die automatische Nachrichtenübersetzung, um Nachrichten in deiner Sprache zu lesen und zu schreiben`,
        toggleLabel: t`Übersetzung aktivieren`,
        selectLabel: t`Nachrichten übersetzen auf:`,
      },
      UserOnboardingLocationSlide: {
        illustrationFileName: t`package_de.png`,
        illustrationAlt: t`Benutzerprofil mit Nachricht, in der die Nachbar*in darum bittet, ihr Paket abzuholen`,
        headline: t`Lass deine Nachbar*innen wissen, wo du zu finden bist`,
        explanation: t`Wenn du zum Beispiel ein Paket für jemand anderen erhältst`,
        teamLocationLabel: t`Adresse`,
        floorLabel: t`Etage`,
        buildingLabel: t`Gebäude`,
        extraLocationDataLabel: t`Zusätzliche Informationen`,
        extraLocationDataPlaceholder: t`Wohnungsnummer, c/o, etc.`,
      },
      UserOnboardingNameSlide: {
        illustrationAlt: t`Nutzerprofil-Mockup mit Profilbild, Name und Adresse`,
        headline: t`Wie heißt du?`,
        explanation: t`Dein Name wird in den Nachrichten und in deinem Profil angezeigt. Dein Nachname hilft den Leuten, deine Tür zu finden.`,
        noAtSignInNames: t`Bitte keine @-Zeichen in Namen verwenden ❤️`,
        firstName: {
          label: t`Vorname`,
          isRequired: t`Bitte sag uns, wie wir dich ansprechen sollen`,
        },
        lastName: {
          label: t`Nachname`,
          isRequired: t`Es ist sehr hilfreich für deine Nachbar*innen, wenn sie denselben Namen sehen, der auf deiner Klingel steht.`,
        },
      },
      UserOnboardingProfileImageSlide: {
        headline: t`Letzter Schritt!`,
        explanation: t`Hilf deinen Nachbar*innen, dich zu erkennen wenn sie dich treffen.`,
        uploadPictureButtonText: t`Wähle ein Bild aus`,
        changePictureButtonText: t`Ändere dein Bild`,
      },
      UserOnboardingFinalSlide: {
        welcomeText: (firstName: UserProfile["firstName"]) => (
          <>
            Hallo {firstName}! <br /> Schön dich kennenzulernen!
          </>
        ),
        welcome: t`Willkommen zu`,
        headerSubtext: t`Du bist bereit!`,
        buttonText: t`Willkommen`,
        illustrationAltText: t`Hausgemeinschaft mit Wohnungen und Leuten, die Dinge teilen`,
      },
      LanguageSettingsPage: {
        title: t`Spracheinstellungen`,
        toggletext: (active: boolean) =>
          `Übersetzung ${active ? "aktiviert" : "deaktiviert"}`,
        deactivatedText: t`Nachrichten werden nicht übersetzt`,
        enabledText: (activeLanguage: string) => (
          <p>
            Nachrichten werden automatisch auf {activeLanguage} übersetzt. Du
            kannst Übersetzungen jederzeit ein-/ausschalten indem du den{" "}
            <IonIcon icon={languageOutline} color="dark" size="small" /> Button
            drückst.
          </p>
        ),
        successfulUpdate: t`Deine Einstellungen für die Nachrichtenübersetzung wurden aktualisiert`,
        localeLabel: t`Oberfläche anzeigen in`,
        localeText: t`Dies steuert das Format von Daten und Zahlen sowie die Sprache von Oberflächenelementen wie dieser Erklärung.`,
        updatedLocale: t`Sprache gewechselt`,
      },
      ImagePicker: {
        takeAPicture: t`Ein Foto machen`,
        pickFromGallery: t`Aus der Galerie wählen`,
      },
      UseBlockUserButtons: {
        block: t`Nutzer blockieren`,
        unblock: t`Nutzer freigeben`,
      },
      ShareButton: {
        share: t`Teilen`,
        copyLink: t`Link kopieren`,
        app: { share: t`Link teilen`, copy: "Link kopieren" },
        invite: {
          share: t`Einladungslink teilen`,
          copy: t`Einladungslink kopieren`,
        },
      },
      SupportUserConversationsPage:
        enUs.translations.SupportUserConversationsPage,
      useEmailActionSheet: {
        copyEmail: t`E-Mail Adresse kopieren`,
        openMailApp: t`In E-Mail App öffnen`,
        header: (email: string) => t`Kontaktiere ${email}`,
      },
      SearchAddressModal: {
        searchPlaceholder: t`Adresse suchen`,
        loadingError: t`Es scheint du hast gerade keine Internetverbindung`,
        noResults: t`Leider konnten wir keine Treffer für diese Adresse finden`,
        apiError: t`Leider ist etwas bei deiner Suche schief gegangen. Probiere es bitte erneut.`,
        initialMessage: t`Tippe eine Adresse ein um zu suchen (wir sind momentan auf Deutschland beschränkt)`,
        missingAddressComponentsError: (keys: GeocodeAddressComponentType[]) =>
          t`In der ausgewählten Adresse fehlen diese Angaben: ${keys
            .map((key) => common.addressComponentNames[key])
            .join(", ")}`,
      },
      GetStartedPage: {
        title: t`Loslegen`,
        subtitle: t`Sieht aus, als wärst du noch kein Mitglied einer Gemeinschaft. Was möchtest du als Nächstes tun?`,
        inviteCodeCard: {
          title: t`Ich habe einen Einladungscode`,
          paragraph: t`Klicke hier, wenn du eine Einladung in deinem Briefkasten oder von einem deiner Nachbarn erhalten hast.`,
          button: t`Einladungscode eingeben`,
        },
        checkMyAddressCard: {
          title: t`Sehe ob es schon eine Gemeinschaft an deiner Adresse gibt`,
          paragraph: t`Klicke hier, um herauszufinden, ob es an deiner Adresse eine Connected Living Gemeinschaft gibt, der du beitreten kannst. Falls nicht, kannst du eine gründen und wir helfen dir dabei, deine Nachbarn einzuladen.`,
          button: t`Adresse überprüfen`,
        },
        noneOfTheAboveCard: {
          title: t`Keine der oben genannten...`,
          paragraph: t`Wir sind hier, um zu helfen! Schick uns eine E-Mail und wir beantworten deine Fragen und helfen dir loszulegen.`,
          button: t`Schreib uns`,
        },
      },
      CheckMyAddressPage: {
        title: t`Überprüfe meine Adresse`,
        subtitle: t`Gib deine Adresse unten ein, um herauszufinden ob es bei dir schon eine Connected Living Gemeinschaft gibt.`,
        cards: {
          address: {
            placeholder: t`Gib deine Adresse ein`,
            button: t`Überprüfen`,
            modalSubmit: t`Adresse überprüfen`,
          },
          inviteCode: {
            title: t`Ich habe einen Einladungscode`,
            text: t`Installiere die mobile App und gib deinen Einladungscode ein, um deiner Gemeinschaft beizutreten.`,
          },
        },
      },
      CheckMyAddressResultsPage: {
        preTitle: t`Gemeinschaften in`,
        subtitle: t`Gute Neuigkeiten! Deine Nachbarn haben eine Gemeinschaft auf Connected Living gegründet!`,
        noResultsSubtitle: t`An dieser Adresse benutzt noch niemand Connected Living.`,
        cards: {
          communityResultCard: {
            installAppAndJoinWithInviteCode: t`Installiere die App und gebe den folgenden Einladungscode ein:`,
            installMobileApp: t`Installiere die App und gebe deinen Einladungscode ein um dieser Gemeinschaft beizutreten`,
          },
          getHelp: {
            title: t`Ich brauche Hilfe`,
            text: t`Wir sind hier, um zu helfen! Schick uns eine E-Mail und wir beantworten deine Fragen und helfen dir loszulegen.`,
          },
          noResults: {
            title: t`Ich möchte eine neue Gemeinschaft erstellen`,
            paragraph: t`Melde deine Adresse an und lade deine Nachbarn ein, Connected Living zu nutzen. Es ist ganz einfach, alle einzuladen: Bestelle gedruckte Einladungen, die du in jeden Briefkasten stecken kannst, oder lade ein PDF herunter, das du selbst ausdrucken kannst.`,
            button: t`Gemeinschaft erstellen`,
          },
          joinWaitingList: {
            title: t`Sag mir Bescheid, wenn hier eine Community gegründet wird`,
            text: t`Willst du nicht der Erste sein? Gib hier deine E-Mail-Adresse ein, um benachrichtigt zu werden, wenn eine Gemeinschaft an dieser Adresse gegründet wird.`,
            button: t`Registrieren`,
            email: {
              label: t`E-Mail`,
            },
            waitingListJoinedSuccessfully: t`Deine E-Mail wurde in die Warteliste aufgenommen!`,
          },
        },
        errors: {
          noGeocodeResults: t`Leider konnten wir diesen Ort nicht finden.`,
          invalidTeamLocation: t`Leider konnten wir diese Adresse nicht valideren. Probiere es erneut.`,
          errorFirestoreResults: t`Leider konnten wir keine Ergebnisse finden. Bist du offline?`,
        },
      },
      ComunityResultCardContent: {
        numberOfMembersAndApartments: ({
          numberOfMembers,
          numberOfApartments,
        }: {
          numberOfMembers: number;
          numberOfApartments: number;
        }) => (
          <IonText color="aubergine-text">
            {t`${numberOfMembers}`}
            {numberOfMembers === 1 ? " Mitglied" : " Mitglieder"} /{" "}
            {t`${numberOfApartments}`}
            {numberOfApartments === 1 ? " Wohnung" : " Wohnungen"}
          </IonText>
        ),
        founded: (createdAt: number) =>
          t`Gegründet ${formatters.formatDate(
            new Date(createdAt),
            "monthAndYear",
          )}`,
      },
      JoinTeamPage: {
        inviteCodeTitle: t`Ich habe einen Einladungscode`,
        paragraph: t`Du kannst einer Gemeinschaft nur durch die Einladung eines anderen Mitglieds beitreten. Wenn du keinen Einladungscode hast, wende dich an unseren Support und wir helfen dir weiter.`,
        subtitle: t`Werde Mitglied in`,
        getHelpCardTitle: t`Ich habe keinen Code`,
        getHelpCardText: t`Frag eine*n deiner Nachbar*innen oder kontaktiere unseren Support und wir helfen dir beizutreten.`,
        errors: {
          faultyUrlParams: t`Wir konnten nicht finden, wonach du gesucht hast. Bitte versuche es noch einmal.`,
          potentiallyOffline: t`Etwas ist schief gelaufen. Bist du offline?`,
        },
      },
      InviteCodeFormCard: {
        errorOccurred: t`Ein Fehler ist aufgetreten. Bitte versuche es erneut.`,
        incorrectInviteCode: t`Wir konnten diesen Einladungscode leider nicht finden. Überprüfe ihn noch einmal.`,
        inviteCode: t`Einladungscode`,
        joinButton: t`Beitreten`,
        needHelp: t`Brauchst du Hilfe?`,
      },
      DeleteAccountActionSheet: {
        header: t`Lösche deinen Account`,
        reasons: {
          justTryingOut: t`Ich wollte die App nur ausprobieren`,
          dontNeedItAnymore: t`Ich brauche die App nichtmehr`,
          badExperience: t`Ich hatte eine schlechte Erfahrung`,
          notSay: t`Ich möchte keinen Grund angeben`,
        },
        confirmationAlert: {
          header: t`Bist du dir sicher das du deinen Account löschen möchtest?`,
          subHeader: t`Vorsicht, du kannst es nicht rückgängig machen.`,
          confirmDeletionButton: t`Account löschen`,
        },
      },
      TeamSwitcher: {
        searchbarPlaceholder: t`Suche Gemeinschaften`,
      },
      TeamLeavePage: {
        title: t`Gemeinschaft Verlassen`,
        submit: t`Endgültig verlassen`,
        inputs: {
          reason: {
            label: (teamName: string) =>
              t`Warum möchtest du ${teamName} verlassen?`,
            options: {
              wrongCommunity: t`Ich bin der falschen Gemeinschaft beigetreten`,
              moved: t`Ich bin umgezogen`,
              noLongerWantToBe: t`Ich möchte nicht mehr in dieser Gemeinschaft sein`,
              unknown: t`Ich möchte keinen Grund angeben`,
            },
          },
        },
      },
      OnboardingActionSheetButtons: {
        localeSwitch: t`Sprache wechseln`,
      },
      MessageComposer: {
        inputPlaceholder: t`Nachricht`,
      },
      MessageListItem: {
        deleted: t`Gelöscht`,
      },
      RequireUpdatedClient: {
        title: t`Deine App braucht ein Update`,
        paragraph: t`Bitte aktualisiere auf die neueste Version, um Connected Living weiterhin nutzen zu können.`,
        updateButton: t`App aktualisieren`,
      },
      PushNotificationSettingsPage: {
        title: t`Mitteilungen`,
        resetPushToken: {
          action: t`Zürucksetzen`,
          description: t`Starte das Mitteilungenssystem neu. Das kann hilfreich sein, wenn du keine Push-Mitteilungen erhältst`,
          success: t`Das Mitteilungssystem wurde zurückgesetzt.`,
        },
        copyPushToken: {
          action: t`Push-Token kopieren`,
          success: t`Push-Token kopiert`,
        },
        pushToken: {
          label: t`Push-Token`,
        },
      },
    };
  },
});
