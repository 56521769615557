import { Preferences } from "@capacitor/preferences";
import { getOptionalStringLiteral } from "@connectedliving/common/lib/firestore/dataHelpers";
import {
  SupportedLocale,
  supportedLocaleValues,
} from "@connectedliving/common/lib/firestore/SupportedLocale";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  LocaleFormatters,
  LocaleTranslations,
} from "src/state/i18n/i18nConfig";
import de from "src/state/i18n/locales/de";
import enDe from "src/state/i18n/locales/en-de";
import enUs from "src/state/i18n/locales/en-us";
import { createContainer } from "src/utilities/createContainer";
import { selectPreferredLocale } from "src/utilities/i18n/selectPreferredLocale";
import { I18nLocale } from "../../utilities/i18n/makeLocale";
import enGb from "./locales/en-gb";

const localeStorageKey = "preferredLocale";

export type LocaleTag = SupportedLocale;

const localesByTags: Record<
  LocaleTag,
  I18nLocale<LocaleTranslations, LocaleFormatters, LocaleTag>
> = Object.freeze({
  "en-us": enUs,
  de,
  "en-gb": enGb,
  "en-de": enDe,
});

const locales = Object.freeze(Object.values(localesByTags));

export type I18n = {
  t: LocaleTranslations;
  format: LocaleFormatters;
  currentLocale: LocaleTag;
  setCurrentLocale(tag: LocaleTag): void;
  performLogOut(): Promise<void>;
};

export function useI18n(): I18n {
  const [userSelectedLocale, setUserSelectedLocale] =
    useState<null | I18nLocale<
      LocaleTranslations,
      LocaleFormatters,
      LocaleTag
    >>(null);
  const [browserLanguageLocale, setBrowserLanguageLocale] = useState(
    selectPreferredLocale(navigator.languages, locales),
  );

  useEffect(() => {
    async function run() {
      const { value } = await Preferences.get({
        key: localeStorageKey,
      });

      const storedLocaleTag = getOptionalStringLiteral(value, {
        permitted: supportedLocaleValues,
        fallback: null,
      });

      if (storedLocaleTag) {
        setUserSelectedLocale(localesByTags[storedLocaleTag]);
      }
    }
    dontAwait(run());
  });

  useEffect(() => {
    const languageChangeEventListener = () => {
      setBrowserLanguageLocale(
        selectPreferredLocale(navigator.languages, locales),
      );
    };

    window.addEventListener("languagechange", languageChangeEventListener);

    return () =>
      window.removeEventListener("languagechange", languageChangeEventListener);
  });

  const displayedLocale = userSelectedLocale ?? browserLanguageLocale;

  const performLogOut = useCallback(async () => {
    await Preferences.remove({ key: localeStorageKey });
  }, []);

  return useMemo(
    () => ({
      t: displayedLocale.translations,
      format: displayedLocale.formatters,
      currentLocale: displayedLocale.tag,
      setCurrentLocale(selectedTag: LocaleTag): void {
        setUserSelectedLocale(localesByTags[selectedTag]);
        dontAwait(
          Preferences.set({
            key: localeStorageKey,
            value: selectedTag,
          }),
        );
      },
      performLogOut,
    }),
    [
      displayedLocale.formatters,
      displayedLocale.tag,
      displayedLocale.translations,
      performLogOut,
    ],
  );
}

const I18nContainer = createContainer(useI18n);
export default I18nContainer;
