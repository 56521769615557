import { deletedUserName } from "@connectedliving/common/lib/cloudFunctions/DeleteUser";
import { getUrl } from "@connectedliving/common/lib/firestore/dataHelpers";
import firestoreSystemUser from "@connectedliving/common/lib/firestore/firestoreSystemUser";
import { Message } from "@connectedliving/common/lib/firestore/Message";
import {
  MessageAttachment,
  MessageAttachmentType,
} from "@connectedliving/common/lib/firestore/MessageAttachment";
import streamSystemUser from "@connectedliving/common/lib/stream/streamSystemUser";
import {
  ActiveUserTrackingProps,
  ChannelTrackingProps,
} from "@connectedliving/common/lib/TrackingEvent";
import convertTimestampValueToDate from "@connectedliving/common/lib/utilities/convertTimestampValueToDate";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { viewUserProfilePageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonSkeletonText,
  IonSpinner,
} from "@ionic/react";
import classNames from "classnames";
import firebase from "firebase/compat/app";
import { documentOutline, languageOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import { FindUserProfileById } from "../../../state/FindUserProfileById";
import FirebaseAppContainer from "../../../state/firebase/FirebaseAppContainer";
import I18nContainer from "../../../state/i18n/I18nContainer";
import MixpanelClientContainer from "../../../state/mixpanel/MixpanelContainer";
import TranslationConfigContainer from "../../../state/TranslationConfigContainer";
import constructImgixUrl from "../../../utilities/constructImgixUrl";
import FallbackProfileImage from "../../../utilities/FallbackProfileImage";
import getTranslatedMessageBody from "../../../utilities/getTranslatedMessageBody";
import css from "./FirestoreMessageListItem.module.css";
import useMessageContextMenu from "./useMessageContextMenu";

type MessageAttachmentItemProps = { attachment: MessageAttachment };

const googleStorageDownloadUrlCache: Record<string, string | undefined> = {};

const MessageAttachmentItem: React.FC<MessageAttachmentItemProps> = ({
  attachment,
}) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const [isLoading, setIsLoading] = useState(
    !googleStorageDownloadUrlCache[attachment.url],
  );
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(
    googleStorageDownloadUrlCache[attachment.url],
  );

  useEffect(() => {
    if (googleStorageDownloadUrlCache[attachment.url]) {
      setDownloadUrl(googleStorageDownloadUrlCache[attachment.url]);
      setIsLoading(false);
    }

    firebaseApp
      .storage()
      .refFromURL(attachment.url)
      .getDownloadURL()
      .then((value) => {
        googleStorageDownloadUrlCache[attachment.url] = value;
        setDownloadUrl(value);
        setIsLoading(false);
      });
  }, [attachment.url, firebaseApp]);

  const onClick = useCallback(async () => {
    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }, [downloadUrl]);

  if (attachment.type === MessageAttachmentType.File) {
    return (
      <div className={css.messageItemAttachmentWrapper}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={classNames(css.messageItemAttachment, {
            [css.messageItemAttachment_clickable as string]:
              !isLoading && downloadUrl,
            [css.messageItemAttachment_loading as string]: isLoading,
          })}
          onClick={isLoading ? undefined : onClick}
          role="link"
          tabIndex={0}
        >
          <div className={css.messageItemAttachmentIcon}>
            {isLoading ? <IonSpinner /> : <IonIcon icon={documentOutline} />}
          </div>
          <div>{attachment.name}</div>
        </div>
      </div>
    );
  }

  throw assertExhausted(attachment.type);
};

export type FirestoreMessageListItemSkeletonProps = {
  fromSelf?: boolean;
  hideAvatar?: boolean;
  textWidth?: number;
};

const FirestoreMessageListItemSkeleton: React.FC<
  FirestoreMessageListItemSkeletonProps
> = ({ fromSelf, hideAvatar, textWidth }) => (
  <IonItem
    lines="none"
    className={classNames({
      [css.messageItem_fromSelf as string]: fromSelf,
    })}
  >
    <IonAvatar slot="start">
      {!hideAvatar && <IonSkeletonText animated />}
    </IonAvatar>
    <div>
      <IonSkeletonText
        animated
        style={{
          width: textWidth || 200,
          height: 18,
          borderRadius: "var(--ion-border-radius)",
        }}
      />
    </div>
  </IonItem>
);

export { FirestoreMessageListItemSkeleton };

type FirestoreMessageListItemProps = {
  teamId: string;
  message: firebase.firestore.QueryDocumentSnapshot<Message>;
  hideAllAvatars: boolean;
  hideAvatar?: boolean;
  spaceBefore?: boolean;
  showTimestamp?: boolean;
  findUserProfileById: FindUserProfileById;
  trackingContext: ActiveUserTrackingProps & ChannelTrackingProps;
};

const FirestoreMessageListItem: React.FC<FirestoreMessageListItemProps> = ({
  teamId,
  message,
  hideAvatar,
  hideAllAvatars,
  spaceBefore,
  showTimestamp,
  findUserProfileById,
  trackingContext,
}) => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });
  const { showTranslation, preferredLanguage } =
    TranslationConfigContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const messageData = message.data();
  const userProfile = findUserProfileById(messageData.creatorId);

  const userNotFound = !userProfile;
  const userDeleted = userProfile?.data().firstName === deletedUserName;
  const avatarImageUrl = userProfile?.data().imageUrl;
  const cdnAvatarImageUrl =
    avatarImageUrl && constructImgixUrl(avatarImageUrl, { w: 400, h: 400 });
  const userFullName = userProfile && formatFullName(userProfile.data());

  const loggedInUserIsMessageAuthor = authUser.uid === messageData.creatorId;

  const history = useHistory();

  const canViewUserProfile =
    userProfile &&
    messageData.creatorId !== streamSystemUser &&
    messageData.creatorId !== firestoreSystemUser &&
    !userDeleted;

  const onAvatarClick = useCallback(() => {
    const authorProfileUrl = viewUserProfilePageUrl({
      teamId,
      userProfileId: messageData.creatorId,
    });
    history.push(authorProfileUrl);
  }, [history, messageData.creatorId, teamId]);

  const translatedMessageBody: string = getTranslatedMessageBody(
    showTranslation,
    preferredLanguage,
    messageData,
    authUser.uid,
  );
  const isTranslationDisplayed = translatedMessageBody !== messageData.body;

  const { presentMessageContextMenu } = useMessageContextMenu({
    message,
    displayedText: translatedMessageBody,
  });
  const bindLongPressEvents = useLongPress(presentMessageContextMenu, {
    threshold: 500,
    detect: LongPressDetectEvents.BOTH,
    cancelOnMovement: true,
  });
  const avatarVisible = !hideAvatar && !loggedInUserIsMessageAuthor;

  const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.target instanceof HTMLAnchorElement && event.target.href) {
        const { href } = event.target;
        const linkText = event.target.textContent;

        const { url, query, domain } = getUrl(href);

        dontAwait(
          track({
            eventName: "Message Link Clicked",

            "link text": linkText,
            "link url": url,
            "link query": query,
            "link domain": domain,

            "message id": message.id,
            "message sender id": messageData.creatorId,
            "message sender name": userFullName || "",
            "message text": messageData.body,
            "message created at": convertTimestampValueToDate(
              messageData.createdAt,
            ).toISOString(),
            "message purpose": messageData.purpose || null,

            "offer id": messageData.offer?.id || null,
            "offer partner id": messageData.offer?.partnerId || null,

            ...trackingContext,
          }),
        );
      }
    },
    [
      message.id,
      messageData.body,
      messageData.createdAt,
      messageData.creatorId,
      messageData.offer?.id,
      messageData.offer?.partnerId,
      messageData.purpose,
      track,
      trackingContext,
      userFullName,
    ],
  );

  return (
    <div
      className={classNames(css.messageItem, {
        [css.messageItem_fromSelf as string]: loggedInUserIsMessageAuthor,
        [css.messageItem_deleted as string]: messageData.deleted,
        [css.messageItem_spaceBefore as string]: spaceBefore,
        [css.messageItem_avatarVisible as string]: avatarVisible,
      })}
    >
      {!hideAllAvatars && (
        <div className={css.messageItemSide as string}>
          {avatarVisible && (
            <IonAvatar onClick={canViewUserProfile ? onAvatarClick : undefined}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {userNotFound || userDeleted ? null : cdnAvatarImageUrl ? (
                <img src={cdnAvatarImageUrl} alt={userFullName} />
              ) : (
                <FallbackProfileImage name={userFullName} />
              )}
            </IonAvatar>
          )}
        </div>
      )}

      <div
        className={classNames(
          {
            "ion-text-end": loggedInUserIsMessageAuthor,
          },
          css.messageItemContent,
        )}
        {...bindLongPressEvents}
      >
        {avatarVisible && userProfile && (
          <div className={css.messageItemName}>
            <strong>
              <small>{formatFullName(userProfile.data())}</small>
            </strong>
          </div>
        )}

        <div className={css.messageItemTextWrapper}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={css.messageItemText} onClick={onClick}>
            {messageData.deleted ? (
              <em>
                <small>{i18n.t.MessageListItem.deleted}</small>
              </em>
            ) : (
              <>
                {messageData.attachments.length > 0 && (
                  <div className={css.messageAttachmentsContainer}>
                    {messageData.attachments.map((attachment) => (
                      <MessageAttachmentItem
                        attachment={attachment}
                        key={attachment.url}
                      />
                    ))}
                  </div>
                )}
                <ReactMarkdown>{translatedMessageBody}</ReactMarkdown>
              </>
            )}
          </div>
          {showTimestamp && (
            <span className={css.messageItemTimestamp}>
              <small>
                {i18n.format.formatDate(
                  convertTimestampValueToDate(messageData.createdAt),
                  "hourAndMinute",
                )}
              </small>
            </span>
          )}
        </div>
      </div>

      {isTranslationDisplayed && (
        <div className={css.messageItemTranslationIndicator}>
          <em>
            <small>
              <IonIcon color="primary" icon={languageOutline} />
            </small>
          </em>
        </div>
      )}
    </div>
  );
};

export default FirestoreMessageListItem;
