import { App, AppState } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { InternalStreamChat } from "@connectedliving/common/lib/stream/InternalStreamChat";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { isPlatform } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import { createContainer } from "src/utilities/createContainer";
import usePluginListener from "src/utilities/usePluginListener";
import { StreamChat, TranslationLanguages, UserResponse } from "stream-chat";
import { Streami18n } from "stream-chat-react";
import LoggedInUserProfileContainer from "../firebase/LoggedInUserProfileContainer";
import I18nContainer from "../i18n/I18nContainer";
import PushNotificationsContainer from "../PushNotificationsContainer";
import usePrevious from "../usePrevious";
import StreamTranslations from "./StreamTranslations";

const STREAM_BASE_URL = requireEnvVar("REACT_APP_STREAM_BASE_URL");
const STREAM_API_KEY = requireEnvVar("REACT_APP_STREAM_API_KEY");

export type StreamChatClient = {
  streamReady: boolean;
  streamChat: InternalStreamChat;
  streamI18n: Streami18n;
};

type StreamChatInitialValue = {
  streamChat: InternalStreamChat;
};

const streamUserTokenStorageKey = "streamToken";
const streamDeviceIdStorageKey = "streamDeviceId";

async function authenticateStreamChatClient(
  streamChat: InternalStreamChat,
  userId: string,
  token: string,
): Promise<void> {
  await streamChat.connectUser(
    blindCast<
      UserResponse<InternalStreamChatGenerics>,
      "the stream chat client fetches all properties of the user, so no need to pass them in here"
    >({ id: userId }),
    token,
  );

  await Preferences.set({
    key: streamUserTokenStorageKey,
    value: token,
  });
}

export function useStreamChat(
  initial?: StreamChatInitialValue,
): StreamChatClient {
  const i18n = I18nContainer.useContainer();
  const [streamI18n] = useState(() => {
    const result = new Streami18n();
    StreamTranslations.forEach((streamTranslation) => {
      const { language, translation, customDayjsLocale } = streamTranslation;
      result.registerTranslation(
        language as TranslationLanguages,
        translation,
        customDayjsLocale,
      );
    });
    return result;
  });
  useEffect(() => {
    dontAwait(
      streamI18n.setLanguage(i18n.currentLocale as TranslationLanguages),
    );
  }, [i18n.currentLocale, streamI18n]);

  const { authUser, cloudFunctions, onLogOut } =
    FirebaseAppContainer.useContainer();
  const [streamChat] = useState(() => {
    const client =
      initial?.streamChat ||
      new StreamChat<InternalStreamChatGenerics>(STREAM_API_KEY, {
        timeout: 60000,
      });
    client.setBaseURL(STREAM_BASE_URL);
    return client;
  });
  const [streamReady, setStreamReady] = useState(false);
  const previousAuthUser = usePrevious(authUser);
  const { userProfile } = LoggedInUserProfileContainer.useContainer();
  const { pushNotificationDeviceId } =
    PushNotificationsContainer.useContainer();

  useEffect(() => {
    async function getStreamTokenAndAuthenticate() {
      if (!authUser || !userProfile?.streamUserExists) return;

      const { value: storedStreamToken } = await Preferences.get({
        key: streamUserTokenStorageKey,
      });

      if (storedStreamToken) {
        try {
          await authenticateStreamChatClient(
            streamChat,
            authUser.uid,
            storedStreamToken,
          );
          setStreamReady(true);
          return;
        } catch (error) {
          dontAwait(Preferences.remove({ key: streamUserTokenStorageKey }));
        }
      }

      const { token } = await cloudFunctions.getStreamUserToken();
      await authenticateStreamChatClient(streamChat, authUser.uid, token);

      setStreamReady(true);
    }

    dontAwait(getStreamTokenAndAuthenticate());
  }, [
    authUser,
    cloudFunctions,
    previousAuthUser,
    pushNotificationDeviceId,
    streamChat,
    userProfile?.streamUserExists,
  ]);

  usePluginListener(
    useCallback(() => {
      async function handleStreamWebsocketConnection(
        state: AppState,
      ): Promise<void> {
        if (streamReady) {
          if (state.isActive) {
            await streamChat.openConnection();
            dontAwait(streamChat.recoverState());
          } else {
            dontAwait(streamChat.closeConnection());
          }
        }
      }
      return App.addListener("appStateChange", handleStreamWebsocketConnection);
    }, [streamChat, streamReady]),
  );

  useEffect(() => {
    async function registerStreamDeviceId(
      userId: string,
      pushNotificationDeviceIdToRegister: string,
    ) {
      const pushService = isPlatform("ios") ? "apn" : "firebase";

      dontAwait(
        streamChat.addDevice(
          pushNotificationDeviceIdToRegister,
          pushService,
          userId,
        ),
      );

      const { value: previousRegisteredDeviceId } = await Preferences.get({
        key: streamDeviceIdStorageKey,
      });

      if (pushNotificationDeviceIdToRegister === previousRegisteredDeviceId)
        return;

      dontAwait(
        Preferences.set({
          key: streamDeviceIdStorageKey,
          value: pushNotificationDeviceIdToRegister,
        }),
      );
    }

    if (authUser && pushNotificationDeviceId && streamReady) {
      dontAwait(registerStreamDeviceId(authUser.uid, pushNotificationDeviceId));
    }
  }, [authUser, pushNotificationDeviceId, streamChat, streamReady]);

  useEffect(
    () =>
      onLogOut(async () => {
        const userId = authUser?.uid || previousAuthUser?.uid;
        if (!userId) return;

        const pendingOperations: Promise<unknown>[] = [];

        setStreamReady(false);
        pendingOperations.push(
          Preferences.remove({ key: streamUserTokenStorageKey }),
        );

        if (pushNotificationDeviceId) {
          pendingOperations.push(
            streamChat.removeDevice(pushNotificationDeviceId, userId),
          );

          pendingOperations.push(
            Preferences.remove({ key: streamDeviceIdStorageKey }),
          );
        }

        await Promise.all(pendingOperations);
        await streamChat.disconnectUser();
      }),
    [
      authUser?.uid,
      onLogOut,
      previousAuthUser?.uid,
      pushNotificationDeviceId,
      streamChat,
    ],
  );

  return {
    streamReady,
    streamChat,
    streamI18n,
  };
}

const StreamChatContainer = createContainer(useStreamChat);
export default StreamChatContainer;
